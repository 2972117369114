.cardContainer {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 30px auto 0 auto; /* Uppdaterat för centrering */
}

.cardContainerSecond {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 30px auto 0 auto; /* Uppdaterat för centrering */
  margin-top: 60px;
}

.leftColumn,
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.leftColumnSecond,
.rightColumnSecond {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.bottomButtons {
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 10px;
}

.logBox {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  font-family: monospace;
  border-radius: 5px;
  flex: 1;
}

.progressContainer {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px auto 0 auto; /* Uppdaterat för centrering */
}

@keyframes blinkingEffect {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.5;
  }
}

.blinkingCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #000000;
  border-radius: 50%;
  animation: blinkingEffect 1.2s infinite;
}

.switchText {
  display: flex;
  align-items: center; /* Se till att innehållet är vertikalt centrerat */
  justify-content: start; /* Lägg till detta för att starta innehållet från vänsterkanten */
  gap: 20px; /* Skapar ett mellanrum mellan texten och switchen */
}

/* Extra styling för label för att säkerställa att texten inte tar upp för mycket plats */
.switchText label {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Om du vill att switchen ska ha ett specifikt utrymme från texten */
.react-switch {
  margin-left: 20px;
  margin-right: 50px; /* Justera detta värde efter önskemål */
}
