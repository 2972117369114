
.SearchBar {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.SearchBar .form-control {
  width: 50%;
  font-size: 1.5em;
}

.main-container {
  display: flex;
  justify-content: center; /* Centrerar flex-items horisontellt */
  padding-top: 20px; /* Justera efter behov */
}

.filter-container {
  position: fixed;
  left: 50px;
  top: 130px; /* Justera detta värde beroende på din navbars höjd */
  width: 250px; /* Eller önskad bredd */
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  z-index: 100; /* För att säkerställa att den är över andra element */
}



.product-list-container {
  flex-grow: 1; /* Tar upp återstående utrymme */
  max-width: 1200px; /* Justera detta värde efter önskad maximal bredd */
  margin-bottom: 200px;
}


