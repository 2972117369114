.custom-form-group {
  margin-bottom: 10px;
}

.custom-label {
  margin-bottom: 0.1rem; /* Anpassa detta värde efter behov */
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #a5e4be;
  border-color: #007e33;
}
