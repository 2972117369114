/* Om dina kort är inuti en container */
.Container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  
  .product-list-container {
    margin-bottom: 200px;  /* eller den storlek du önskar */
  }
  