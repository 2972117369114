.portalContainer {
    max-width: 1200px;
    margin: auto; /* Centrerar containern */
    margin-bottom: 50px;
  }
  
.portalCardContainer {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px auto 0 auto;
}