/* Generella stilar för hela kortet */
/* Om dina kort är inuti en container */
.Container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  

.Card {
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    will-change: transform, box-shadow;
    width: 100%;  /* Justera denna värde för att ställa in kortets bredd */
    margin: auto;  /* Centrerar kortet */
  }
  
  /* Hover-effekt */
  .Card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Stil för bildområdet */
  .Card-Img {

    object-fit: cover;
  }
  
  .Card-Body {
    display: flex;
    flex-direction: column; /* Gör detta till en flex-kolumn */
    padding: 15px;
    justify-content: space-between; /* Fördela utrymme mellan titeln och texten */
  }
  
  /* Stil för kortets titel */
  .Card-Title {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  .Card-Img {
    object-fit: cover; /* Detta säkerställer att bilden täcker hela utrymmet utan att förlora sitt proportionella förhållande */
    width: 100%; /* Bredden på bilden. Du kan justera detta värde för att passa dina behov */
    height: 400px; /* Fast höjd. Ändra detta värde för att passa designen på dina kort */
  }
  
  
  .Card-Text {
    font-size: 1em;
    color: #333;
    overflow: hidden; /* Förhindrar att innehållet spiller ut */
    text-overflow: ellipsis; /* Lägger till ellipsis (...) när texten är för lång */
    white-space: nowrap; /* Ser till att texten inte bryts över flera rader */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Antal rader som visas innan texten kapas */
    -webkit-box-orient: vertical;
    height: 1.5em; /* Höjden måste justeras beroende på radhöjd och antal rader */
  }
  
  
  .Badge {
    font-size: 1.0em; /* För att göra texten större */
    position: absolute; /* För att placera badgen på en specifik plats inom kortet */
    top: 10px; /* Avstånd från toppen */
    right: 10px; /* Avstånd från höger */
    z-index: 1; /* För att säkerställa att badgen visas ovanpå andra element */
  }
  

